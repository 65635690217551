import {
  FormField,
  NCButton,
  NCInputRadioGroup,
  NCWell,
} from '@daupler/nexus-components';
import { GasQuestionaire } from '../../GasLeakQuestionaire';
import { DatumType } from '../../types';
import { BooleanRadioGroup } from '../../components/BooleanRadioGroup';
import './IncidentFormSummary.css';
import { ArcGisAddressCandidate } from '../../arcgis';
import { classifyResultToFormOption } from '../../utils/classifier';

type IncidentFormSummaryProps = {
  data: SessionData;
  formState: {
    canSuggestCategories: FormField<boolean>;
    contactName: FormField<string>;
    description: FormField<string>;
    isChoosingCategory: FormField<boolean>;
    odorComplaint: FormField<boolean>;
    datums: FormField<IncidentFormDatum[]>;
    handlerId: FormField<string>;
    dispatchTargetId: FormField<string>;
    location: FormField<string>;
    locationGeocoded: FormField<ArcGisAddressCandidate | undefined>;
    urgent: FormField<boolean | undefined>;
  };
  onChange: (name: string, value: unknown) => void;
  prevStep: () => void;
  classifyIncidentResults: (ClassifyIncidentResult & { id: string; })[];
};

export function IncidentFormSummary({
  data,
  formState,
  prevStep,
  onChange,
  classifyIncidentResults,
}: IncidentFormSummaryProps) {
  const getAddressLine2 = () => {
    if (!formState.locationGeocoded.value) { return ''; }
    let line2 = '';
    if (formState.locationGeocoded.value.attributes.City) {
      line2 += formState.locationGeocoded.value.attributes.City;
    }
    if (formState.locationGeocoded.value.attributes.Region) {
      if (line2.length) { line2 += ', '; }
      line2 += formState.locationGeocoded.value.attributes.Region;
    }
    return line2;
  };

  const getHandlerDisplayValue = (handler: IncidentHandler) => {
    if (handler.entity.name !== data.entity) {
      return `${handler.displayName} (${handler.entity.name})`;
    }
    return handler.displayName;
  };

  const groupAndSortHandlersByEntity = (handlers: IncidentHandler[]) => {
    const handlersByEntity: Record<string, IncidentHandler[]> = handlers
      .reduce((result, handler) => ({
        ...result,
        [handler.entity.name]: [
          ...(result[handler.entity.name] ?? []),
          handler,
        ].sort((l, r) => {
          if (l.displayName > r.displayName) { return 1; }
          if (l.displayName < r.displayName) { return -1; }
          return 0;
        }),
      }), {});
    return Object.entries(handlersByEntity).sort(([entityName1], [entityName2]) => {
      if (entityName1 > entityName2) { return 1; }
      if (entityName1 < entityName2) { return -1; }
      return 0;
    }).reduce((result, [, h]) => [...result, ...h], []);
  };

  const getFormOptions = () => {
    const formOptions = classifyIncidentResults
      .map((category) => classifyResultToFormOption(category, data))
      .filter((category) => category !== null)
      .map((formOption) => ({
        ...formOption,
        icon: (<i className={`fa-light ${formOption.icon}`} />),
      }));
    if (!formOptions.length) {
      onChange(formState.isChoosingCategory.name, false);
      onChange(formState.canSuggestCategories.name, false);
      return [];
    }
    return formOptions;
  };

  const handleCategorySelection: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const formOption = getFormOptions().find((fo) => fo.id === event.target.id);
    if (!formOption) { return; }
    onChange(formState.handlerId.name, formOption.handlerId);
    onChange(formState.urgent.name, undefined);
    onChange(
      formState.dispatchTargetId.name,
      event.target.id,
    );
  };

  const toggleCategorySelector = () => {
    onChange(formState.isChoosingCategory.name, !formState.isChoosingCategory.value);
    onChange(formState.dispatchTargetId.name, '');
    onChange(
      formState.handlerId.name,
      '',
    );
    onChange(
      formState.urgent.name,
      undefined,
    );
  };

  const isSelectedCategoryUrgent = () => {
    const result = classifyIncidentResults.find((r) => r.id === formState.dispatchTargetId.value);
    if (!result) { return false; }
    return result.overrideDispatchData.urgent;
  };

  const handleUrgencyChange = (value: boolean) => {
    onChange(formState.urgent.name, value);
  };

  return (
    <>
      <div className="nc-flex nc-flex--align_center nc-flex--justify_between">
        <span className="nc-t-body_medium_mobile nc-t-grey_900">
          Summary
        </span>
        <NCButton
          color={NCButton.colors.GREY}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          appearance={NCButton.appearances.OUTLINE}
          size={[[NCButton.breakpoints.MOBILE, NCButton.sizes.XS]]}
          onClick={prevStep}
        >
          Edit
        </NCButton>
      </div>

      <NCWell className="nc-l-mt_100_mobile nc-l-pa_200_mobile">
        <div className="nc-t-body_bold_mobile nc-t-grey_700">
          {formState.contactName.value}
        </div>

        <div className="dispatch-incident_form_summary__description nc-l-mt_utilities_50_mobile">
          &ldquo;
          {formState.description.value}
          &rdquo;
        </div>

        <div className="dispatch-incident_form_summary__location nc-l-mt_200_mobile">
          <div className="nc-t-h1_regular_mobile nc-l-ml_utilities_50_mobile">
            <i className="fa-light fa-map-marker-alt" />
          </div>
          <div className="nc-l-ml_200_mobile dispatch-incident_form_summary__location__line_1">
            {formState.locationGeocoded.value ? (
              <>
                <span className="nc-t-sub_text_bold_mobile">
                  {formState.locationGeocoded.value.attributes.ShortLabel}
                </span>
                <br />
                <span className="nc-t-sub_text_light_mobile">
                  {getAddressLine2()}
                </span>
              </>
            ) : (
              <span className="nc-t-sub_text_bold_mobile">
                {formState.location.value}
              </span>
            )}
          </div>
        </div>
      </NCWell>

      {data.extensions.length ? (
        <>
          <div className="nc-t-body_medium_mobile nc-t-grey_900 nc-l-mt_400_mobile nc-l-mb_100_mobile">
            Custom Questions
          </div>
          <GasQuestionaire
            data={data}
            form={formState}
            onOdorComplaintChange={(value) => {
              onChange(
                formState.odorComplaint.name,
                value,
              );
            }}
            onDatumChange={(incomingDatum) => {
              onChange(
                formState.datums.name,
                formState.datums.value.map((datum) => {
                  if (datum.type !== DatumType.gas_leak_form) { return datum; }
                  return {
                    ...datum,
                    value: {
                      ...datum.value,
                      ...incomingDatum.value,
                    },
                  };
                }),
              );
            }}
          />
        </>
      ) : null}

      {formState.isChoosingCategory.value ? (
        <NCInputRadioGroup
          name={formState.handlerId.name}
          className="nc-l-mt_400_mobile"
          label="Top Suggestions"
          labelAction={(
            <span className="nc-t-sub_text_light_mobile nc-t-grey_700">
              <i className="fa-light fa-brain-circuit" />
              <span className="nc-l-ml_utilities_50_mobile">
                Identified by Daupler
                <strong className="nc-t-sub_text_medium_mobile">AI</strong>
                ™
              </span>
            </span>
          )}
          options={getFormOptions()}
          onChange={handleCategorySelection}
          value={formState.dispatchTargetId.value}
        />
      ) : (
        <NCInputRadioGroup
          name={formState.handlerId.name}
          className="nc-l-mt_400_mobile"
          label="Dispatch to"
          options={groupAndSortHandlersByEntity(data?.incidentHandlers ?? [])
            .map((handler) => ({
              id: handler.id,
              label: getHandlerDisplayValue(handler),
              'data-testid': handler.id,
            }))}
          onChange={(event) => onChange(formState.handlerId.name, event.target.id)}
          value={formState.handlerId.value}
        />
      )}
      {formState.canSuggestCategories.value ? (
        <NCButton
          appearance={NCButton.appearances.LINK}
          width={[[NCButton.breakpoints.MOBILE, NCButton.widths.HUG]]}
          className="nc-l-mt_utilities_50_mobile"
          onClick={toggleCategorySelector}
          data-testid="link-choose-handler"
        >
          <span className="nc-t-sub_text_light_mobile">
            {formState.isChoosingCategory.value
              ? 'Choose where to dispatch instead'
              : 'Choose a Daupler AI™ suggestion instead'}
          </span>
        </NCButton>
      ) : null}

      <BooleanRadioGroup
        name={formState.urgent.name}
        className="nc-l-mt_400_mobile"
        label="Urgency"
        value={formState.urgent.value}
        valueMap={{
          urgent: true,
          nonurgent: false,
        }}
        onChange={handleUrgencyChange}
        disabled={data?.options.urgencyControl === 'FORCE_DISPATCH'}
        options={[
          {
            id: 'urgent',
            label: 'Dispatch for immediate response',
            ...(isSelectedCategoryUrgent() ? {
              hint: (
                <span className="nc-t-sub_text_light_mobile nc-t-grey_700">
                  <i className="fa-light fa-brain-circuit" />
                  <span className="nc-l-ml_utilities_50_mobile">
                    Suggested by Daupler
                    <strong className="nc-t-sub_text_medium_mobile">AI</strong>
                    ™
                  </span>
                </span>
              ),
            } : {}),
            'data-testid': 'urgent',
          },
          {
            id: 'nonurgent',
            label: 'Can be resolved next business day',
            'data-testid': 'nonurgent',
          },
        ]}
      />
    </>
  );
}
