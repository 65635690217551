import { captureException } from '@sentry/react';

/* eslint-disable no-console */
export const logger = {
  ...console,
  error: (message: string, ...args: any[]) => {
    if (process.env.REACT_APP_ENVIRONMENT.toUpperCase() !== 'LOCAL') {
      captureException(message, { extra: args as any });
    }
    console.error(...args);
  },
};
