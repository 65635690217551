import { FormField, NCButton, useForm } from '@daupler/nexus-components';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { v4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { IncidentFormSummary } from './IncidentFormSummary';
import { IncidentFormInformation } from './IncidentFormInformation';
import { ArcGisAddressCandidate, ArcGisGeocodeParams, findAddressCandidates } from '../../arcgis';
import './IncidentFormWizard.css';
import { DatumType, ExtensionType } from '../../types';
import { DispatchApi } from '../../api';
import { nonEmptyRegex, nonEmptyWithNewLinesRegex, nonUndefinedOrNull } from '../../utils/validation';
import { getMapExtentStringFromMapBounds, searchStringHasCity } from '../../utils/location';
import { logger } from '../../logger';

export type FormInformation = {
  description: string;
  location?: LocationInput;
  name?: string;
  phone_number?: string;
  phone_extension?: string;
  datums?: IncidentFormDatum[];
};

enum IncidentFormWizardStep {
  INFORMATION = 0,
  SUMMARY,
}

type IncidentFormWizardProps = {
  api: DispatchApi;
  data: SessionData | null;
  onSubmitFormInformation: (data: FormInformation) => Promise<ClassifyIncidentResponse>;
  onSubmit: (input: Partial<CreateIncidentInput>) => Promise<void>;
};

export function IncidentFormWizard({
  api,
  data,
  onSubmitFormInformation,
  onSubmit,
}: IncidentFormWizardProps) {
  const description: FormField<string> = {
    name: 'description',
    validMessage: '',
    invalidMessage: 'Please describe the issue',
    validate: (value) => nonEmptyWithNewLinesRegex.test(value),
    value: '',
    initialValue: '',
  };
  const location: FormField<string> = {
    name: 'location',
    validMessage: '',
    invalidMessage: 'Location is required',
    validate: () => true,
    value: '',
    initialValue: '',
  };
  const locationGeocoded: FormField<ArcGisAddressCandidate | undefined> = {
    name: 'locationGeocoded',
    validMessage: '',
    invalidMessage: 'Geocoded location is required',
    validate: () => true,
    value: undefined,
    initialValue: undefined,
  };
  const contactName: FormField<string> = {
    name: 'contactName',
    validMessage: '',
    invalidMessage: 'Contact name is required',
    validate: (value) => nonEmptyRegex.test(value),
    value: '',
    initialValue: '',
  };
  const contactPhoneNumber: FormField<string> = {
    name: 'contactPhoneNumber',
    validMessage: '',
    invalidMessage: 'Contact phone number is required',
    validate: () => true,
    value: '',
    initialValue: '',
  };
  const contactPhoneExtension: FormField<string> = {
    name: 'contactPhoneExtension',
    validMessage: '',
    invalidMessage: '',
    validate: () => true,
    value: '',
    initialValue: '',
  };
  const canSuggestCategories: FormField<boolean> = {
    name: 'canSuggestCategories',
    validMessage: '',
    invalidMessage: '',
    validate: () => true,
    value: false,
    initialValue: false,
  };
  const isChoosingCategory: FormField<boolean> = {
    name: 'isChoosingCategory',
    validMessage: '',
    invalidMessage: '',
    validate: () => true,
    value: false,
    initialValue: false,
  };
  const dispatchTargetId: FormField<string> = {
    name: 'dispatchTargetId',
    validMessage: '',
    invalidMessage: '',
    validate: () => true,
    value: null,
    initialValue: null,
  };
  const handlerId: FormField<string> = {
    name: 'handlerId',
    validMessage: '',
    invalidMessage: 'Must select a dispatch handler',
    validate: (value) => nonEmptyRegex.test(value),
    value: '',
    initialValue: '',
  };
  const urgent: FormField<boolean | null> = {
    name: 'urgent',
    validMessage: '',
    invalidMessage: 'Please choose the urgency',
    validate: (value) => nonUndefinedOrNull.test(value),
    value: data?.options.urgencyControl === 'FORCE_DISPATCH'
      ? true
      : undefined,
    initialValue: null,
  };
  const datums: FormField<IncidentFormDatum[]> = {
    name: 'datums',
    validMessage: '',
    invalidMessage: '',
    validate: () => true,
    value: [],
    initialValue: [],
  };
  const odorComplaint: FormField<boolean> = {
    name: 'odorComplaint',
    validMessage: '',
    invalidMessage: '',
    validate: () => true,
    value: false,
    initialValue: false,
  };

  const {
    formState,
    isFormValid,
    onChange: onFormValueChange,
    resetValues,
    validateForm,
  } = useForm({
    canSuggestCategories,
    dispatchTargetId,
    description,
    isChoosingCategory,
    location,
    locationGeocoded,
    contactName,
    contactPhoneNumber,
    contactPhoneExtension,
    handlerId,
    urgent,
    datums,
    odorComplaint,
  });

  const [isDirty, setIsDirty] = useState(true);
  const onChange = useCallback((name: string, value: any) => {
    setIsDirty(true);
    onFormValueChange(name, value);
  }, [onFormValueChange]);

  useEffect(() => {
    if (!data?.options.urgencyControl) { return; }
    if (data?.options.urgencyControl !== 'FORCE_DISPATCH') { return; }
    if (data?.options.urgencyControl === 'FORCE_DISPATCH' && formState.urgent.value === true) {
      return;
    }
    onFormValueChange(formState.urgent.name, true);
  }, [
    data?.options.urgencyControl,
    formState.urgent.name,
    formState.urgent.value,
    onFormValueChange,
  ]);

  const getLocationCandidates = async (
    sessionOptions: { geocodeOrigin: Point; initialMapBounds: MapBounds; } | null,
    searchString: string,
  ) => {
    if (!sessionOptions || !searchString) { return []; }

    const { geocodeOrigin, initialMapBounds } = sessionOptions;
    const abortController = new AbortController();
    const params: ArcGisGeocodeParams = {
      category: [
        'Subaddress',
        'Point Address',
        'Street Address',
        'Intersection',
        'Block',
        'Park',
        'Trail',
        // 'POI',
      ].join(','),
      SingleLine: searchString,
      location: `${geocodeOrigin.lng},${geocodeOrigin.lat}`,
      searchExtent: searchStringHasCity(searchString)
        ? undefined
        : getMapExtentStringFromMapBounds(initialMapBounds),
    };

    const result = await findAddressCandidates(params, abortController);
    const candidates: ArcGisAddressCandidate[] = result.candidates
      .filter((candidate) => api.utils.isSupportedAddressType(candidate.attributes.Addr_type))
      // Ignore address duplicates
      .reduce((list, candidate) => {
        if (list.some((c) => c.address === candidate.address)) {
          return list;
        }
        return [...list, candidate];
      }, [] as ArcGisAddressCandidate[]);
    return candidates;
  };

  useEffect(() => {
    const datum = formState.datums.value.find((d) => d.type === DatumType.gas_leak_form);
    const hasExtension = data
      && data.extensions?.some((extension) => extension.extensionType === ExtensionType.GAS);
    if (!hasExtension || datum) { return; }
    onChange(
      formState.datums.name,
      [
        ...formState.datums.value,
        {
          type: DatumType.gas_leak_form,
          value: {
            version: '1',
          },
        } as IncidentFormDatum,
      ],
    );
  }, [data, formState.datums.name, formState.datums.value, onChange]);

  const isStepValid = (step: number) => {
    switch (step) {
      case IncidentFormWizardStep.INFORMATION:
        return formState.description.isValid
          && formState.contactName.isValid;
      case IncidentFormWizardStep.SUMMARY:
        return isFormValid();
      default:
        return false;
    }
  };

  const createDatum = (type: string, value: any) => ({
    type,
    value,
    source: 'DISPATCH_ASSIST',
  });

  const createGasDatum = () => {
    const datum = formState.datums.value.find((d) => d.type === DatumType.gas_leak_form);

    if (!formState.odorComplaint.value) { return []; }
    if (!datum) { return []; }

    return [createDatum(datum.type, datum.value)];
  };

  const getLocationValue = () => {
    try {
      return api.utils.arcgis2compat(formState.locationGeocoded.value);
    } catch (err) {
      logger.error(err.message, err);
    }
    return { raw: formState.location.value };
  };

  const [step, setStep] = useState(IncidentFormWizardStep.INFORMATION);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const submitForm = useMutation(async () => {
    validateForm();
    if (!isFormValid()) { return; }

    try {
      setIsSubmitting(true);
      await onSubmit({
        description: formState.description.value.trim(),
        location: getLocationValue(),
        name: formState.contactName.value.trim(),
        phoneNumber: formState.contactPhoneNumber.value.trim(),
        phoneExtension: formState.contactPhoneExtension.value.trim(),
        handlerId: formState.handlerId.value.trim(),
        urgent: formState.urgent.value,
        datums: [
          ...createGasDatum(),
        ],
      });
      resetValues();
      setStep(0);
      toast('Incident created!', { toastId: 'incident-created', autoClose: 3000 });
    } catch (err) {
      toast('Failed to create Incident', { toastId: 'incident-error', autoClose: 3000 });
      logger.error('Failed to create Incident', err);
    } finally {
      setIsSubmitting(false);
    }
  });

  const isFinalStep = () => step === IncidentFormWizardStep.SUMMARY;
  const prevStep = () => {
    setStep((current) => current - 1);
  };

  const [
    classifyIncidentResults,
    setClassifyIncidentResults,
  ] = useState<(ClassifyIncidentResult & { id: string })[]>([]);
  const submitFormInformation = useMutation(async () => {
    if (!isDirty) {
      setStep((state) => state + 1);
      return;
    }
    try {
      setIsSubmitting(true);
      const response = await onSubmitFormInformation({
        description: formState.description.value,
        datums: [
          ...createGasDatum(),
        ],
        location: getLocationValue(),
        name: formState.contactName.value,
        phone_extension: formState.contactPhoneExtension.value,
        phone_number: formState.contactPhoneNumber.value,
      });
      onChange(formState.canSuggestCategories.name, response.results.length > 0);
      onChange(formState.isChoosingCategory.name, response.results.length > 0);
      setClassifyIncidentResults(response
        .results.map((result) => ({ ...result, id: v4() })));
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setIsDirty(false);
      setIsSubmitting(false);
      setStep((state) => state + 1);
    }
  });

  const nextStep: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (step === IncidentFormWizardStep.INFORMATION) {
      submitFormInformation.mutate();
    }

    if (step === IncidentFormWizardStep.SUMMARY) {
      submitForm.mutate();
    }
  };

  const getSubmitButtonLabel = () => {
    if (!isFinalStep()) { return 'Next'; }
    return 'Submit';
  };

  if (!data) { return null; }

  return (
    <form onSubmit={nextStep} className="dispatch-incident_form_wizard">
      <div className="dispatch-incident_form_wizard__body">
        {step === IncidentFormWizardStep.INFORMATION ? (
          <IncidentFormInformation
            data={data}
            formState={formState}
            onChange={onChange}
            getLocationCandidates={(searchString) => getLocationCandidates(
              data.options,
              searchString,
            )}
          />
        ) : null}
        {step === IncidentFormWizardStep.SUMMARY ? (
          <IncidentFormSummary
            data={data}
            classifyIncidentResults={classifyIncidentResults}
            formState={formState}
            onChange={onChange}
            prevStep={() => {
              setIsDirty(false);
              prevStep();
            }}
          />
        ) : null}
      </div>

      <div className="dispatch-incident_form_wizard__action">
        <NCButton
          type="submit"
          disabled={!isStepValid(step) || isSubmitting}
          appearance={NCButton.appearances.SOLID}
          color={NCButton.colors.SUCCESS}
          data-testid="form-submit"
        >
          {isSubmitting ? (
            <span>Submitting...</span>
          ) : (
            getSubmitButtonLabel()
          )}
        </NCButton>
      </div>
    </form>
  );
}
